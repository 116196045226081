function SplideNavigation(Splide, Components, options) {
    function mount() {

        let navigation = Splide.root.querySelector('[data-element="navigation"]');

        if (navigation !== null) {
            let pages = navigation.querySelectorAll('[data-item]');
            if (pages.length > 0) {
                pages.forEach(page => {
                    let pageId = parseInt(page.getAttribute('data-item'));
                    if (pageId === Splide.index) {
                        page.classList.add('slider__navigation-page--active');
                    }
                    page.addEventListener('click', e => {
                        e.preventDefault();
                        Splide.go(pageId);
                        let active = navigation.querySelector('.slider__navigation-page--active');
                        if (active !== null) {
                            active.classList.remove('slider__navigation-page--active');
                        }
                        page.classList.add('slider__navigation-page--active');
                    })
                })
            }
        }

        Splide.on('move', (newIndex) => {
            let active = navigation.querySelector('.slider__navigation-page--active');
            if (active !== null) {
                active.classList.remove('slider__navigation-page--active');
            }
            let page = navigation.querySelector('[data-item="' + newIndex + '"]');
            if (page !== null) {
                page.classList.add('slider__navigation-page--active');
            }
        })


    }

    return {
        mount,
    }
}

export default SplideNavigation;