import Splide from "@splidejs/splide";
import SplideNavigation from "../splideNavigation";

let slider = document.querySelector('.js-slider');
if (slider !== null) {
    new Splide(slider, {
        type: 'loop',
        arrows: false,
        pagination: false,
        autoplay: true,
    }).mount({
        SplideNavigation
    });
}

let manufacturersCarousel = document.querySelector('.js-manufacturers-carousel');
if (manufacturersCarousel !== null) {
    new Splide(manufacturersCarousel, {
        type: 'loop',
        arrows: true,
        pagination: false,
        autoplay: true,
        autoWidth: true,
        perPage: 8,
        gap: '50px',
        breakpoints: {
            1360: {
                perPage: 8,
                gap: '30px'
            },
            1200: {
                perPage: 7,
                gap: '30px'
            },
            1000: {
                perPage: 5,
                gap: '20px'
            },
            900: {
                perPage: 5,
                gap: '20px'
            },
        }
    }).mount();
}